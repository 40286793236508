import 'react-calendar/dist/Calendar.css';

import { useEffect, useState } from 'react';
import { Alert, Media } from 'reactstrap';
import Calendar from 'react-calendar';

import { formatDate, getSwedishDayOfWeek } from './utils/helpers';
import { del, get, post } from './utils/httpClient';
import qs from 'qs';
import { ConfirmButton } from './components/ConfirmButton';
import { DatesList } from './components/DatesList';
import { TimeSelection } from './components/TimeSelection';

const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
const authorizationParams = `?teamId=${params.teamId}&hash=${params.hash}`;

function Competition() {
  const [competition, setCompetition] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [addedDates, setAddedDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      if (params.competitionId && params.teamId && params.hash) {
        getCompetition();
      }
    })();
  }, []);

  const getCompetition = async () => {
    const response = await get(`competitions/${params.competitionId}${authorizationParams}`);

    if (response.competition) {
      setAddedDates(response.dates);
      setCompetition(response.competition);
    }
  };

  const onDateSelected = (date) => {
    const strDate = formatDate(date);
    const weekday = getSwedishDayOfWeek(date);
    const currentSelectedTime = addedDates.find((d) => d.date === strDate)?.time || null;

    setSelectedDate({ date: strDate, weekday, currentSelectedTime });
  };

  const onDateTimeSelected = async (selectedTime) => {
    setError(null);
    setLoading(true);

    try {
      const response = await post(`competitions/${params.competitionId}/dates${authorizationParams}`, { date: selectedDate.date, time: selectedTime });
      setAddedDates(response);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
      setSelectedDate(null);
    }
  };

  const onDeleteDateTime = async (id) => {
    setLoading(id);
    const response = await del(`competitions/${params.competitionId}/dates/${id}${authorizationParams}`);
    setAddedDates(response);
    setLoading(false);
  };

  const confirmDates = async () => {
    setLoading(true);
    const { error } = await post(`competitions/${params.competitionId}/confirm${authorizationParams}`);
    console.log('error', error);
    if (!error) {
      getCompetition();
    }
    setLoading(false);
  };

  const isSelected = (date) => addedDates.find((d) => d.date === formatDate(date));

  const isDatesAdded = addedDates?.length > 0;
  const teamConfirmed = competition?.teamConfirmedAt;
  const addedTimes = addedDates?.filter((d) => !d.deletedAt).length;
  const confirmButtonDisabled = addedTimes < 3;

  if (!competition) return null;

  // console.log('addedDates', addedDates);

  return (
    <>
      <div className="matchplay">
        {!teamConfirmed && (
          <Calendar
            className="calendar"
            locale="sv"
            minDate={new Date(competition.startDate)}
            maxDate={new Date(competition.endDate)}
            defaultActiveStartDate={new Date(competition.startDate)}
            next2Label={null}
            prev2Label={null}
            onChange={onDateSelected}
            tileClassName={({ date, view }) => view === 'month' && isSelected(date) && 'marked-date'}
          />
        )}

        {teamConfirmed && <Alert className="primary mt-3">Dina valda datum/tider har skickats in</Alert>}
        <div className="selected-dates">
          <h4 className="mt-3">Valda datum/tider</h4>
          {!teamConfirmed && <p className="small mb-0">Lägg till minst 3 tider för att kunna bekräfta.</p>}
          {!teamConfirmed && isDatesAdded && <ConfirmButton className="mt-0" confirm={confirmDates} loading={loading} disabled={confirmButtonDisabled} />}
          {isDatesAdded && <DatesList dates={addedDates} onDelete={onDeleteDateTime} loading={loading} teamConfirmed={teamConfirmed} />}
        </div>
      </div>

      {selectedDate && <TimeSelection close={() => setSelectedDate(null)} date={selectedDate} selected={onDateTimeSelected} loading={loading} error={error} />}
    </>
  );
}

export default Competition;

import { Alert, Button, ButtonGroup, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import useWindowSize from '../utils/hooks/useWindowSize';

export const TimeSelection = ({ close, date: { date, weekday, currentSelectedTime }, selected, loading, error }) => {
  const { isXS } = useWindowSize();

  return (
    <Modal isOpen centered backdrop toggle={close}>
      <ModalHeader toggle={close}>
        {date} ({weekday})
      </ModalHeader>
      <ModalBody>
        {currentSelectedTime && (
          <Alert color="info" className="p-2">
            Nuvarande val: {currentSelectedTime}
          </Alert>
        )}
        <p className="fw-bold">Välj när under dagen ni kan</p>
        <ButtonGroup vertical={isXS}>
          <Button color="matchplay" disabled={loading} onClick={() => selected('Hela dagen')}>
            Hela dagen
          </Button>
          <Button color="matchplay" disabled={loading} onClick={() => selected('Förmiddag')}>
            Förmiddag (8-12)
          </Button>
          <Button color="matchplay" disabled={loading} onClick={() => selected('Eftermiddag')}>
            Eftermiddag (12-16)
          </Button>
          <Button color="matchplay" disabled={loading} onClick={() => selected('Kväll')}>
            Kväll (16-)
          </Button>
        </ButtonGroup>
        <br />
        {loading && <Spinner color="matchplay" className="mt-3" />}
        {error && (
          <Alert color="danger" className="mt-3">
            {error}
          </Alert>
        )}
      </ModalBody>
    </Modal>
  );
};

import './App.scss';
import { Media } from 'reactstrap';
import Competition from './Competition';
import Admin from './Admin';

const logotype = require('./assets/img/logotype.png');

console.log('version: ', process.env.REACT_APP_VERSION);

const path = window.location.pathname;

function App() {
  return (
    <div className="matchplay">
      <Media src={logotype} className="logotype" alt="Matchplay logo" />
      {path === '/admin' ? <Admin /> : <Competition />}
    </div>
  );
}

export default App;

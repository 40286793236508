import 'react-calendar/dist/Calendar.css';

import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';

import { get, post, put } from './utils/httpClient';
import { LuInfo } from 'react-icons/lu';
import { FaCheck } from 'react-icons/fa';
import { RxReload } from 'react-icons/rx';

const formValues = {
  teamHomeName: '',
  teamHomeEmail: '',
  teamAwayName: '',
  teamAwayEmail: '',
  startDate: '',
  endDate: '',
};

function Admin() {
  const [competitions, setCompetitions] = useState(null);
  const [addTeam, setAddTeam] = useState(false);
  const [teamInfo, setTeamInfo] = useState(null);
  const [logs, setLogs] = useState(null);
  const [formData, setFormData] = useState(formValues);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      getCompetitions();
    })();
  }, []);

  const getCompetitions = async () => {
    const response = await get(`admin/competitions`);
    console.log(response);

    if (response) {
      setCompetitions(response);
    }
  };

  const onClickTeam = async (id) => {
    const response = await get(`admin/competitions/${id}/dates`);
    if (response) {
      setTeamInfo(response);
    }

    const logs = await get(`admin/competitions/${id}/logs`);
    setLogs(logs);
  };

  const onInputChange = ({ target }) => {
    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
  };

  const onMobileInputChange = ({ target }) => {
    const { name, value } = target;
    setFormData({ ...formData, [name]: value.replace(/\D/g, '') });
  };

  const onAddTeams = async () => {
    setError(null);
    const { error } = await post(`admin/competitions`, formData);
    if (error) {
      setError(error);
    } else {
      setAddTeam(false);
      setFormData({ ...formValues, startDate: formData.startDate, endDate: formData.endDate });
      getCompetitions();
    }
  };

  const onResentInviteClick = async (id) => {
    await put(`admin/competitions/${id}/invite`);
    getCompetitions();
  };

  // console.log('teamInfo', teamInfo);

  return (
    <div className="admin">
      <h2>Admin</h2>
      <Button color="primary" onClick={() => setAddTeam(true)}>
        Lägg till tävling
      </Button>

      <h3>Tävlingar</h3>
      <Table striped>
        <thead className="text-left">
          <tr>
            <td></td>
            <td>Hemmalag</td>
            <td>Bortalag</td>
            <td>Startdatum</td>
            <td>Slutdatum</td>
            <td>Inbjudan skickad</td>
            <td>Bekräftelse skickad</td>
          </tr>
        </thead>
        <thead>
          {competitions?.map((c) => (
            <tr key={c.id}>
              <td>
                <LuInfo size={25} onClick={() => onClickTeam(c.id)} style={{ cursor: 'pointer' }} />
              </td>
              <td className={c.teamHomeConfirmedAt && 'team-confirmed'}>{c.teamHomeName}</td>
              <td className={c.teamAwayConfirmedAt && 'team-confirmed'}>{c.teamAwayName}</td>
              <td>{c.startDate}</td>
              <td>{c.endDate}</td>
              <td>
                {c.inviteSentAt?.substring(0, 10)} {c.inviteSentAt && <ResendInviteButton onClick={() => onResentInviteClick(c.id)} />}
              </td>
              <td>{c.confirmationSentAt?.substring(0, 10)}</td>
            </tr>
          ))}
        </thead>
      </Table>
      {/* {!teamConfirmed && <Calendar className="calendar" locale="sv" minDate={today} maxDate={lastDayOfYear} next2Label={null} prev2Label={null} onChange={onDateSelected} />} */}
      {addTeam && (
        <Modal isOpen toggle={() => setAddTeam(false)}>
          <ModalHeader>Lägg till tävling</ModalHeader>
          <ModalBody className="text-start">
            <form>
              <h5>Tävlingsinfo</h5>
              <FormGroup>
                <Input type="date" name="startDate" value={formData.startDate} placeholder="Startdatum" onChange={onInputChange} className="mb-1" />
                <Input type="date" name="endDate" value={formData.endDate} placeholder="Slutdatum" onChange={onInputChange} />
              </FormGroup>
              <h5>Hemmalag</h5>
              <FormGroup>
                <Input type="text" name="teamHomeName" value={formData.teamHomeName} placeholder="Namn" onChange={onInputChange} className="mb-1" />
                <Input type="email" name="teamHomeEmail" value={formData.teamHomeEmail} placeholder="E-post" onChange={onInputChange} className="mb-1" />
                <Input type="email" name="teamHomeMobile" value={formData.teamHomeMobile} placeholder="Mobil (0707100200)" onChange={onMobileInputChange} />
              </FormGroup>
              <h5>Bortalag</h5>
              <FormGroup>
                <Input type="text" name="teamAwayName" value={formData.teamAwayName} placeholder="Namn" onChange={onInputChange} className="mb-1" />
                <Input type="email" name="teamAwayEmail" value={formData.teamAwayEmail} placeholder="E-post" onChange={onInputChange} className="mb-1" />
                <Input type="email" name="teamAwayMobile" value={formData.teamAwayMobile} placeholder="Mobil (0707100200)" onChange={onMobileInputChange} />
              </FormGroup>
            </form>
          </ModalBody>
          <ModalFooter>
            {error && <Alert color="danger">{error}</Alert>}
            <Button color="danger" onClick={() => setAddTeam(false)}>
              Stäng
            </Button>
            <Button color="primary" onClick={onAddTeams}>
              Lägg till
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {!!teamInfo && (
        <Modal isOpen toggle={() => setTeamInfo(null)}>
          <ModalHeader>Datum/Tider</ModalHeader>
          <ModalBody className="text-start">
            <p>
              <FaCheck /> betyder att lagen har matchat datum/tiden men inte nödvändigtvis att de bestämt sig
            </p>
            <h5>Hemmalag</h5>
            <div className="selected-dates">
              {teamInfo.homeTeam?.map((d) => (
                <p key={d.id} className={d.deletedAt && 'deleted'}>
                  {d.date} - {d.time} {d.matched && <FaCheck />}
                </p>
              ))}
            </div>
            <h5>Bortalag</h5>
            <div className="selected-dates">
              {teamInfo.awayTeam?.map((d) => (
                <p key={d.id} className={d.deletedAt && 'deleted'}>
                  {d.date} - {d.time} {d.matched && <FaCheck />}
                </p>
              ))}
            </div>
            <h5>Loggar</h5>
            <Table striped>
              <tbody>
                {logs?.map((l) => (
                  <tr key={l.id}>
                    <td>
                      {l.createdAt?.substring(0, 19)} - {l.action}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => setTeamInfo(null)}>
              Stäng
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
}

export default Admin;

const ResendInviteButton = ({ onClick, loading }) => (
  <UncontrolledDropdown size="sm" style={{ display: 'inline-table' }}>
    <DropdownToggle color="primary" disabled={loading}>
      {loading && <Spinner size="sm" color="light" className="me-2" />}
      <RxReload style={{ cursor: 'pointer' }} />
    </DropdownToggle>
    <DropdownMenu>
      <DropdownItem onClick={onClick}>Skicka inbjudan på nytt?</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

import { useState, useEffect } from 'react';

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isXS: window.innerWidth < 576,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;

// xs (extra small): <576px
// sm (small): ≥576px
// md (medium): ≥768px
// lg (large): ≥992px
// xl (extra large): ≥1200px
// xxl (extra extra large): ≥1400px

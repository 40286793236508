const BASE_URL = process.env.REACT_APP_API_ENDPOINT || 'http://127.0.0.1:8000/';

const httpClient = async (url, method, body = null, headers = {}) => {
  try {
    const response = await fetch(`${BASE_URL}${url}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...headers,
      },
      body: body ? JSON.stringify(body) : null,
    });

    if (!response.ok) {
      const errorText = await response.text();
      const errorData = errorText ? JSON.parse(errorText) : null;
      return { error: errorData?.message || 'Something went wrong' };
    }

    const responseText = await response.text();
    return responseText ? JSON.parse(responseText) : {};
  } catch (error) {
    return { error: error.message };
  }
};

const get = (url, headers = {}) => httpClient(url, 'GET', null, headers);
const post = (url, body, headers = {}) => httpClient(url, 'POST', body, headers);
const put = (url, body, headers = {}) => httpClient(url, 'PUT', body, headers);
const del = (url, headers = {}) => httpClient(url, 'DELETE', null, headers);

export { get, post, put, del };

import { DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';

export const ConfirmButton = ({ className, confirm, loading, disabled }) => {
  return (
    <UncontrolledDropdown size="sm" className={`my-3 ${className}`}>
      <DropdownToggle color="success" disabled={loading || disabled}>
        {loading && <Spinner size="sm" color="light" className="me-2" />}
        Bekräfta valda tider
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem style={{ textWrap: 'pretty' }} onClick={confirm}>
          Vill du bekräfta valda tider och meddela er motståndare?
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

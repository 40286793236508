import { Spinner, Table } from 'reactstrap';
import { MdOutlineCancel } from 'react-icons/md';

export const DatesList = ({ dates, onDelete, loading, teamConfirmed }) => {
  return (
    <Table responsive striped borderless>
      <tbody>
        {dates?.map((d) => (
          <tr key={d.id} className={d.deletedAt ? 'deleted' : null}>
            <td>{d.fdate}</td>
            <td>{d.time}</td>
            <td>
              {!teamConfirmed && !d.deletedAt && (
                <>
                  {loading === d.id ? (
                    <Spinner color="matchplay" size="sm" />
                  ) : (
                    <span className="icon">
                      <MdOutlineCancel size={25} color="red" style={{ marginLeft: 20 }} onClick={() => onDelete(d.id)} />
                    </span>
                  )}
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
